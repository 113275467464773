import img1 from "@/assets/images/JewelryMall/item-tips.png";
import img2 from "@/assets/images/JewelryMall/5.png";
import img3 from "@/assets/images/JewelryMall/6.png";
import img4 from "@/assets/images/JewelryMall/7.png";
import img5 from "@/assets/images/JewelryMall/8.png";
import img6 from "@/assets/images/JewelryMall/9.png";
import img7 from "@/assets/images/JewelryMall/10.png";
import img8 from "@/assets/images/JewelryMall/11.png";
export const titleList = [
  {
    imgUrl: img1,
    id: 0,
    name: "推荐",
  },
  {
    imgUrl: img2,
    id: 1,
    name: "手套",
  },
  {
    imgUrl: img3,
    id: 2,
    name: "匕首",
  },
  {
    imgUrl: img4,
    id: 3,
    name: "手枪",
  },
  {
    imgUrl: img5,
    id: 5,
    name: "微型冲锋枪",
  },
  {
    imgUrl: img6,
    id: 4,
    name: "步枪",
  },
  // {
  //   imgUrl: img7,
  //   id: 6,
  //   name: "狙击枪",
  // },
  {
    imgUrl: img8,
    id: 7,
    name: "重型武器",
  },
];

export const tipsContent =
  "本网站所有活动均为消遣娱乐、打发无聊，请勿沉迷；禁止利用互动活动进行赌博/欺诈/作弊/刷积分等违法违规行为，若有发现，我们将取消您的活动参与资格，同一件CS:GO饰品不同磨损度可能存在外观差异较大的情况，我们仅保证开箱获得的饰品在同一磨损区间内盲盒概率不是累积的，以30%开3次并不意味着有90%的机会。";
export const options = [
  {
    title: "会员",
    id: 1,
  },
  {
    title: "主播",
    id: 2,
  },
  {
    title: "机器人",
    id: 3,
  },
];

export const getOptionsList = (id) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: [
          {
            imgUrl: "",
            goodUrl: "",
            prob: "50",
          },
          {
            imgUrl: "",
            goodUrl: "",
            prob: "50",
          },
          {
            imgUrl: "",
            goodUrl: "",
            prob: "50",
          },
          {
            imgUrl: "",
            goodUrl: "",
            prob: "50",
          },
          {
            imgUrl: "",
            goodUrl: "",
            prob: "75",
          },
          {
            imgUrl: "",
            goodUrl: "",
            prob: "50",
          },
          {
            imgUrl: "",
            goodUrl: "",
            prob: "75",
          },
        ],
      });
    }, 1000);
  });
};
export const tabList = ["热门道具", "掉落记录", "十佳掉落"];
export const priceSearch = ["0-50", "50-100", "100-200", "200-500"];
export const tableRecordList1 = [
  {
    title: "掉落时间",
    width: "25%",
    index: "time",
    center: "center",
    render: true,
  },
  {
    title: "获得玩家",
    width: "22%",
    index: "userList",
    center: "left",
    render: true,
  },
  {
    title: "道具渠道",
    width: "20%",
    index: "type",
    center: "center",
    render: true,
  },
  {
    title: "",
    width: "10%",
    index: "boxList",
    render: true,
  },
  {
    title: "获得道具",
    width: "25%",
    index: "get",
    center: "center",
    render: true,
  },
];
export const tableRecordList3 = [
  {
    title: "成功概率",
    width: "25%",
    index: "time",
    center: "center",
  },
  {
    title: "次数统计",
    width: "15%",
    index: "name",
    center: "center",
    img: {
      index: "",
      style: {},
    },
  },
  {
    title: "次数/占比",
    width: "20%",
    index: "type",
    center: "center",
    render: true,
  },
  {
    title: "次数 / 占比",
    width: "30%",
    index: "get",
    center: "center",
    render: true,
  },
];

export const tableData = {
  total: 15,
  list: [
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
  ],
};
