<template>
  <div class="view-lucky-detail">
    <div class="header-box">
      <div class="back-box2" @click="$router.go(-1)"></div>
      <div>{{ DetailData.name }}</div>
    </div>
    <div class="center-box">
      <div class="center-bg"></div>
      <div class="center-text">
        {{DetailData.dura_alias}}
      </div>
      <div class="center-core">
        <div class="black-box" v-show="AnimationShow == 1"></div>
        <van-overlay :show="AnimationShow == 1">
          <Dynamic :NumIdx="NumIdx" :DetailList="DetailList" :Awardlist="AwardArr" :Dynamic_checked="Dynamic_checked" :Dynamic_num="Dynamic_num" @setAnimation="setAnimation" @setEquipment="setEquipment" v-show="AnimationShow == 1">
          </Dynamic>
        </van-overlay>
        <div class="sound-box point" title="声音开启或关闭">
          <img src="../../assets/images/OpenBox/shengyin1.png" v-show="soundStatus" @click="mp3PlayOnClick" alt="" srcset="">
          <img src="../../assets/images/OpenBox/shengyin2.png" v-show="!soundStatus" @click="mp3PlayOnClick" alt="" srcset="">
        </div>
        <div class="animation-box point" title="动画开启或关闭">
          <img src="../../assets/images/OpenBox/donghua1.png" v-show="!Animation_checked" @click="() => this.Animation_checked = true" alt="" srcset="">
          <img src="../../assets/images/OpenBox/donghua2.png" v-show="Animation_checked" class="dhbtn" @click="() => this.Animation_checked = false" alt="" srcset="">
        </div>
        <div class="left-box">
          <div class="left-box-foo">
            <img style="width:100%" :src="DetailData.cover" alt="" />
          </div>
        </div>
        <div class="main-box-wrap">
          <div class="main-box">
            <el-progress type="circle" :show-text="false" :stroke-width="3*htmlFontSize/100" :width="134.63*htmlFontSize/100" define-back-color="rgba(255,255,255,0)" color="#CE772E" stroke-linecap="butt" :percentage="NumIdx"></el-progress>
            <div class="text">
              <div class="t1">{{NumIdx}}%</div>
              <div class="t2"></div>
            </div>
            <div :style="`transform:rotate(${(NumIdx/100*360)-90}deg)`" class="circle-bg">
            </div>
          </div>
        </div>
        <!-- <div class="right-box">
          <div class="right-box-foo"></div>
        </div> -->
      </div>
      <div class="odd-box">
        <div class="odd-list">
          <div v-for="item,index in oddList" @click="NumIdx=item" :key="index" class="odd-item point" :class="{'odd-item-active':NumIdx==item}">
            {{item}}%
          </div>
        </div>
      </div>
      <div class="slider-box">
        <div class="slider-left">
          物品爆率
        </div>
        <div class="slider-center-wrap">
          <div class="slider-center-left"></div>
          <div class="slider-center">
            <div class="slider-center-70">
              <el-slider v-model="NumIdx" :min="5" :max="75" :show-tooltip="false"></el-slider>
              <div :style="`left:${NumIdx/0.7}%`" class="slider-btn">
                {{NumIdx}}%
              </div>
            </div>
          </div>
          <div class="slider-center-right"></div>
        </div>
        <div class="slider-right">
          75%
        </div>
      </div>
      <div class="start-box">
        <div @click="PostOpen" v-show="AnimationShow == '0'" class="start-btn point">
          <Cprice size="0.14" :price="((DetailData.bean||0) * (NumIdx / 100)).toFixed(2)" />
          <div style="margin-left:0.08rem">开始</div>
        </div>
        <div v-show="AnimationShow !== '0'" style="opacity:0" class="start-btn">
        </div>
      </div>
      <div v-if="showNoticeBox" class="notice-box-wrap">
        <div class="notice-box">
          <div class="text">
            <van-notice-bar scrollable text="本网站所有活动均为消遣娱乐、打发无聊，请勿沉迷；禁止利用互动活动进行赌博/欺诈/作弊/刷积分等违法违规行为，若有发现，我们将取消您的活动参与资格，同一件CS:GO饰品不同磨损度可能存在外观差异较大的情况，我们仅保证开箱获得的饰品在同一磨损区间内盲盒概率不是累积的，以30%开3次并不意味着有90%的机会。" />
          </div>
          <div class="notice-icon"></div>
          <div @click="showNoticeBox=false" class="close-icon point"></div>
        </div>
      </div>
      <div class="user-list-wrap">
        <div class="user-list-box">
          <div class="user-list">
            <div v-for="item,index in optionList" class="user-item" :class="{'user-item-active':item.get_award.id==$route.query.id}" :key="index">
              <div class="img">
                <img style="width:100%;height:100%;" :src="item.user?.avatar" alt="">
              </div>
              <div v-if="parseInt(item?.percent)" class="odd">
                <div class="elips1" style="margin-bottom:0.02rem">{{item?.user?.name}}</div>
                {{ parseInt(item?.percent).toFixed(0) }}%
              </div>
              <div class="foo">
                <img style="height:100%;" :src="item.get_award?.cover" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="float-box">
      <Show v-if="WinningShow" :WinningShow="WinningShow" @setShow="setShow" :Awardlist="Awardlist"></Show>
    </div>
  </div>
</template>

<script>
import Details from "@/components/PubOpen/Details.vue";
import Dynamic from "@/components/PubOpen/Dynamic.vue";
import Equipment from "@/components/PubOpen/Equipment.vue";
import Show from "@/components/PubOpen/Show.vue";
import { AwardGet, LuckyOpen, PersonalInfo, Luckyhistory } from "@/network/api.js";
import { mapState, mapMutations } from "vuex";
import { tipsContent, options } from "./index.js";
import swiper from "./components/swiper.vue";
export default {
  name: "Open",
  data() {
    return {
      DetailData: {}, //箱子信息
      NumIdx: 5, //箱子比列
      DetailList: [], //箱内物品
      Animation_checked: false, //动画开关
      WinningShow: false, //奖品遮罩层
      Awardlist: [], //获得奖品数据
      AwardArr: [], //获得奖品数据(操作后)
      Dynamic_checked: "0", //播放动画('0':不播放,'1':播放)
      Dynamic_num: 0, //奖品数量
      AnimationShow: "0", //动画显示隐藏'0':不显示,'1':显示)
      EquipmentItem: {}, //下方奖品栏子级
      EquipmentShow: "0", //下方奖品栏显示
      WaterBallArr: [0.05, 0.04, 0.03],
      tipsContent,
      tipsShow: true,
      options,
      // 下方当前物品掉落概率
      optionList: [],
      optionCurrent: 0,
      disabled: false, // 默认点击
      audioplay: false, //声音播放
      htmlFontSize: 100,
      oddList: [5, 10, 33, 50, 75],
      showNoticeBox: true
    };
  },

  //activated
  created() {
    this.ChangeAudioStatus(true);
    this.htmlFontSize = parseFloat(getComputedStyle(window.document.documentElement)['font-size']);
    this.GetAwardGet();
    this.getList();
  },
  computed: {
    ...mapState(["soundStatus", "mp3Start", 'mp3End']),
  },
  methods: {
    ...mapMutations(["ChangeAudioStatus"]),
    mp3PlayOnClick() {
      if (this.soundStatus) {
        this.ChangeAudioStatus(false);
      } else {
        this.ChangeAudioStatus(true);
      }
    },
    prev() {
      this.$refs.swiperComRef.slideTo();
    },
    getList() {
      let id = this.$route.query.id;
      Luckyhistory(id).then((res) => {
        this.optionList = res.data.data;
      });
      // const res =  getOptionsList();
      // this.optionList = res.data;
    },
    //宝箱详情
    GetAwardGet() {
      AwardGet(this.$route.query.id).then((res) => {
        this.DetailData = res.data.data;
        let Arr = [];
        Arr.push(res.data.data.cover, {
          cover: require("@/assets/images/Lucky/cheap.png"),
        });
        this.DetailList = Arr.concat(Arr);
      });
    },

    //开箱
    PostOpen() {
      this.disabled = true;
      LuckyOpen(this.$route.query.id, this.NumIdx / 100).then((res) => {
        if (res.data.code == 200) {
          this.GetPersonalInfo();
          this.Awardlist = [res.data.data];
          if (res.data.data.name == this.DetailData.name) {
            this.AwardArr = [res.data.data];
          } else {
            this.AwardArr = [
              {
                cover: require("@/assets/images/Lucky/cheap.png"),
              },
            ];
          }

          if (this.Animation_checked == true) {
            this.WinningShow = true;
            this.getList();
            this.disabled = false;
          } else {
            this.AnimationShow = "1";
            this.EquipmentShow = "1";
            this.disabled = true;
            setTimeout(() => {
              this.Dynamic_checked = "1";
              this.Dynamic_num = this.Awardlist.length;
            }, 100);
          }
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
          this.GetPersonalInfo();
        }

      });
    },

    //水型球
    echartsMit() {
      let dom = document.getElementById("WaterBall");
      let myChart = this.$echarts.init(dom);
      myChart.setOption({
        series: [
          {
            type: "liquidFill",
            data: this.WaterBallArr,
            color: ["#e96113", "#e99e1d", "#E9B10E"],
            backgroundStyle: {
              borderWidth: 1,
              color: "#453a20",
            },
            itemStyle: {
              shadowBlur: 0,
            },
            outline: {
              borderDistance: 0,
              itemStyle: {
                borderWidth: 3,
                borderColor: "#E9B10E",
                shadowBlur: 20,
              },
            },
            label: {
              normal: {
                textStyle: {
                  color: "#fff",
                  insideColor: "yellow",
                  fontSize: 40,
                },
              },
            },
          },
        ],
      });
    },

    onChange(val) {
    },

    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.User(res.data.data);
      });
    },

    //接收子级(关闭奖品遮罩层)
    setShow(v) {
      this.WinningShow = v;
      this.EquipmentShow = "0";
    },

    //接收子级(动画结束)
    setAnimation(v) {
      this.WinningShow = v;
      this.getList();
      this.AnimationShow = "0";
      this.Dynamic_checked = "0";
      this.disabled = false;
    },

    //接收子级(下方奖品栏)
    setEquipment(v) {
      this.EquipmentItem = this.Awardlist[v - 1];
    },

    ...mapMutations(["User"]),
  },

  components: {
    Details,
    Dynamic,
    Equipment,
    Show,
    swiper,
  },
};
</script>

<style lang="scss">
// .black-box {
//   position: fixed;
//   width: 100vw;
//   height: 100vh;
//   left: 0;
//   top: 0;
//   z-index: 996;
//   background: rgba(0, 0, 0, 0.85);
// }
.view-lucky-detail {
  width: 100%;
  // padding: 0.38rem 2.6rem;
  padding-top: 0.18rem;
  .header-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
    font-size: 0.16rem;
    margin-bottom: 0.02rem;
    .back-box2 {
      position: absolute;
      width: 0.25rem;
      height: 0.14rem;
      left: 0.2rem;
      top: 0.02rem;
      z-index: 3;
      background: url(../../assets/images/new/back-icon.png) no-repeat center;
      background-size: 100% 100%;
    }
  }
  .center-box {
    width: 100%;
    position: relative;
    // min-height: 5.95rem;
    .center-bg {
      left: 0;
      top: 0;
      position: absolute;
      width: 100%;
      height: 2.03rem;
      background: url(../../assets/images/new/bg1.png) no-repeat center;
      background-size: 100% 100%;
      z-index: 0;
    }
    .center-text {
      width: 100%;
      text-align: center;
      font-size: 0.12rem;
      margin-bottom: 0.22rem;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }
    .center-core {
      width: 100%;
      height: 2.03rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.28rem;
      position: relative;
      z-index: 391;
      // padding: 0 0.5rem;
      .sound-box {
        width: 0.24rem;
        height: 0.24rem;
        position: absolute;
        right: 0.74rem;
        top: 0.2rem;
        z-index: 392;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .animation-box {
        width: 0.24rem;
        height: 0.24rem;
        position: absolute;
        right: 0.3rem;
        top: 0.2rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .left-box {
        width: 1.34rem;
        height: 1.34rem;
        flex-shrink: 0;
        // background: url(../../assets/images/new/bg2.png) no-repeat center;
        // background-size: 100% 100%;
        // background: red;
        position: absolute;
        left: 1.19rem;
        top: 0.49rem;
        z-index: 3;
        .left-box-foo {
          width: 1.08rem;
          height: 0.8rem;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .main-box-wrap {
        width: 1.4443rem;
        height: 1.4995rem;
        flex-shrink: 0;
        padding: 0.1rem 0.05rem 0.05rem 0.04rem;
        background: url(../../assets/images/new/bg3.png) no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        left: 1.15rem;
        top: 0.39rem;
        .main-box {
          width: 100%;
          height: 100%;
          position: relative;

          .text {
            position: absolute;
            left: 54%;
            bottom: 0.06rem;
            transform: translateX(-50%);
            font-size: 0.14rem;
            color: #ffa800;
            text-align: center;
            .t2 {
              font-size: 0.14rem;
            }
          }
          .circle-bg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../../assets/images/new/bg5.png) no-repeat center;
            background-size: 100% 100%;
            transform: rotate(0deg);
            transition: all 0.6s;
          }
        }
      }
      .right-box {
        width: 3.31rem;
        height: 2.72rem;
        flex-shrink: 0;
        background: url(../../assets/images/new/bg4.png) no-repeat center;
        background-size: 100% 100%;
        position: relative;
        .right-box-foo {
          background: url(../../assets/images/new/cheap.png) no-repeat center;
          background-size: 100% 100%;
          width: 2.69rem;
          height: 2.17rem;
          position: absolute;
          left: 0.39rem;
          top: -0.62rem;
        }
      }
    }
    .odd-box {
      width: 100%;
      padding: 0 0.44rem;
      margin-bottom: 0.18rem;
      position: relative;
      z-index: 50;
      .odd-list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .odd-item {
          width: 0.55rem;
          height: 0.26rem;
          background: url(../../assets/images/new/bg13-1.png) no-repeat center;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.1rem;
        }
        .odd-item-active {
          background: url(../../assets/images/new/bg13-2.png) no-repeat center;
          background-size: 100% 100%;
        }
      }
    }
    .slider-box {
      display: flex;
      align-items: center;
      padding: 0 0.22rem;
      margin-bottom: 0.19rem;
      .slider-left {
        margin-right: 0.1rem;
        flex-shrink: 0;
        font-size: 0.12rem;
      }
      .slider-center-wrap {
        width: 100%;
        height: 0.14rem;
        // background: pink;
        margin-right: 0.1rem;
        position: relative;
      }
      .slider-center {
        width: 100%;
        height: 0.04rem;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        background: rgba(29, 29, 45, 1);
      }
      .slider-center-70 {
        width: 70%;
        height: 0.04rem;
        position: absolute;
        left: 5%;
        top: 0;
        // background: green;
        border-radius: 0.04rem;
        z-index: 6;
        .slider-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.37rem;
          height: 0.14rem;
          background: url(../../assets/images/new/bg11.png) no-repeat center;
          background-size: 100% 100%;
          font-size: 0.1rem;
          position: absolute;
          z-index: 7;
          top: -0.05rem;
          margin-left: -0.13rem;
          cursor: pointer;
        }
      }
      .slider-center::before {
        content: "";
        width: 5%;
        height: 100%;
        background: #373773;
        display: block;
        left: 0;
        top: 0;
      }
      .slider-center::after {
        content: "";
        width: 40%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
      }
      .slider-right {
        flex-shrink: 0;
        font-size: 0.12rem;
      }
    }
    .start-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.18rem;
      .start-btn {
        width: 1.83rem;
        height: 0.34rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.14rem;
        border-radius: 0.04rem;
        background: linear-gradient(
          to right,
          rgba(56, 56, 118, 1),
          rgba(34, 34, 72, 1)
        );
      }
    }
    .notice-box-wrap {
      margin-bottom: 0.18rem;
      padding: 0rem 0.2rem;
      .notice-box {
        width: 100%;
        height: 0.3rem;
        padding: 0.05rem 0.26rem;
        font-size: 0.1rem;
        color: #585896;
        position: relative;
        background: url(../../assets/images/new/bg9.png) no-repeat center;
        background-size: 100% 100%;
        .notice-icon {
          position: absolute;
          left: 0.09rem;
          top: 0.09rem;
          width: 0.12rem;
          height: 0.12rem;
          background: url(../../assets/images/new/bg6.png) no-repeat center;
          background-size: 100% 100%;
        }
        .close-icon {
          position: absolute;
          right: 0.12rem;
          top: 0.11rem;
          width: 0.08rem;
          height: 0.08rem;
          background: url(../../assets/images/new/bg7.png) no-repeat center;
          background-size: 100% 100%;
        }
        .text {
          .van-notice-bar {
            background-color: unset;
            color: #585896;
            height: 0.2rem;
            line-height: 0.2rem;
          }
        }
      }
    }

    .user-list-wrap {
      .user-list-box {
        display: flex;
        align-items: center;
        width: 100%;
        // height: 2.2rem;
        .user-list-text {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.62rem;
          height: 100%;
          background: linear-gradient(
            to bottom,
            rgba(18, 7, 37, 1),
            rgba(30, 28, 54, 1)
          );
          margin-right: 0.04rem;
          flex-shrink: 0;
          padding: 0 0.2rem;
        }
        .user-list {
          width: 100%;
          display: flex;
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          // height: 100%;
          padding: 0 0.2rem;
          margin-bottom: 0.2rem;
          .user-item {
            width: 1.64rem;
            height: 0.4rem;
            margin-right: 0.1%;
            // transform: rotate(-90deg);
            flex-shrink: 0;
            background: url(../../assets/images/new/bg10.png) no-repeat center;
            background-size: 100% 100%;
            display: flex;
            // padding-top: 0.26rem;
            padding: 0.08rem;
            margin-bottom: 0.04rem;
            padding-left: 0.1rem;
            .img {
              width: 0.26rem;
              height: 0.26rem;
              // margin: 0 auto;
              // margin-bottom: 0.18rem;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 0.14rem;
              flex-shrink: 0;
            }
            .odd {
              font-size: 0.1rem;
              // margin: 0 auto;
              // margin-bottom: 0.44rem;
              text-align: left;
              // display: flex;
              // align-items: center;
              // justify-content: center;
              margin-right: 0.2rem;
              flex-shrink: 0;
              width: 0.5rem;
            }
            .foo {
              width: 0.26rem;
              height: 0.26rem;
              // margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .user-item-active {
            background: url(../../assets/images/new/bg10-at.png) no-repeat
              center;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>
