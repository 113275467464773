<template>
  <div class="swiper-class" v-if="list.length > 0">
    <div ref="swiperRef" class="swiper-wrapper-box" vertical>
      <div class="swiper-slide" style="display: flex" >
        <div class="item-box" v-for="(item, index) in list" :key="index"
          :class="heightNum(item.get_award_id) ? 'item-box-height' : ''">
          <div class="top">
            <div class="pic">
              <img style="width:100%;height:100%;" :src="item.user?.avatar" alt="" srcset="">
            </div>
            <span style="padding: 0 0.05rem;">{{ parseInt(item?.percent).toFixed(0) }}%</span>
          </div>
          <div class="bottom">
            <img class="imgbox" :src="item.get_award?.cover" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    swiperSlides: {
      type: Number,
      default: 3,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      maxNum: "",
      enterid: null//当前物品id
    };
  },
  created() {
    this.getid()
  },
  methods: {
    getid() {
      this.enterid = this.$route.query.id
    },
    getSwiper() {
      return this.$refs.swiperRef.$swiper;
    },
    slideTo(type = "prev") {
      if (type === "prev") {
        this.$refs.swiperRef.prev()
      } else {
        this.getSwiper.slideNext();
      }
    },
    heightNum(val) {
      if (val == this.enterid) {
        return true;
      }
      return false;
    },
  },
  watch: {
    list: function () {
      const n = this.list[0]?.prob;
      this.list.forEach((item) => {
        if (item.prob > n) {
          this.maxNum = item?.prob;
        } else {
          this.maxNum = n;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.swiper-class {
  height: 1.95rem;
  overflow: hidden;

  .swiper-slide {
    width: 3.7rem;
    overflow-y: scroll;
    margin: 0 auto;

    // height: 1.89rem!important;
    .item-box {
      width: 0.61rem;
      height: 1.54rem;
      background: #003366;
      border: 0.02px solid #e07a2f;
      display: flex;
      flex-direction: column;
      margin-top: 0.06rem;
      margin-left: 0.04rem;
      box-sizing: border-box;

      .top {
        background: #003366;
        flex: 1;
        text-align: center;
        font-size: 0.16rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #99ccff;

        .pic {
          width: 0.37rem;
          height: 0.37rem;
          overflow: hidden;
          background: #084780;
          border: 1px solid #fff;
          border-radius: 50%;
          box-sizing: border-box;
          margin: 0.11rem auto 0.37rem;
        }
      }

      .bottom {
        width: 0.59rem;
        height: 0.48rem;
        background: #084780;
        display: flex;
        justify-content: center;
        align-items: center;

        .imgbox {
          display: block;
          width: 95%;
          height: 75%;
        }
      }
    }

    :nth-child(20n) {
      margin-right: 0;
    }

    .item-box-height {
      width: 0.61rem;
      height: 1.54rem;
      // background: #003366;
      // border: 0.02px solid #66ccff;
      display: flex;
      flex-direction: column;
      margin-top: 0.06rem;
      margin-left: 0.04rem;
      box-sizing: border-box;

      .top {
        background: #ff9933;
      }

      .bottom {
        background: #eb8621;
        height: 0.48rem;
      }
    }
  }
}
</style>
